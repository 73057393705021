import React from "react"
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import dateFormat from "../../helpers/commonhelpers/dateFormat"

const UserColumns = (handleActionTableUser, user, handleArchiveUser) => {
  // console.log(user)
  const isHidden = () => {
    if (user?.role !== "SuperAdmin") return true
    else return false
  }

  const isUser = () => {
    if (user?.role === "User") return true
    else return false
  }

  return [
    {
      dataField: "merchantId",
      text: "Merchant Id",
      sort: true,
      searchable: true,
      hidden: isHidden(),
      csvExport: !isHidden(),
      formatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return <>N/A</>
        } else {
          return <>{cellContent}</>
        }
      },
    },
    {
      dataField: "merchantName",
      text: "Merchant Name",
      sort: true,
      hidden: isHidden(),
      csvExport: !isHidden(),
      searchable: true,
      formatter: (cellContent, row) => {
        if (cellContent === null) {
          return <>N/A</>
        } else {
          return <>{cellContent}</>
        }
      },
    },

    {
      dataField: "title",
      text: "Title",
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return <>N/A</>
        } else {
          return <>{cellContent}</>
        }
      },
      csvFormatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return "N/A"
        } else {
          return cellContent
        }
      },
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return <b>N/A</b>
        } else {
          return <b>{cellContent}</b>
        }
      },
    },
    {
      dataField: "userName",
      text: "Username",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return <b>N/A</b>
        } else {
          return <b>{cellContent}</b>
        }
      },
    },

    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return <>N/A</>
        } else {
          return <>{cellContent}</>
        }
      },
      csvFormatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return "N/A"
        } else {
          return cellContent
        }
      },
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return <>N/A</>
        } else {
          return <>{cellContent}</>
        }
      },
      csvFormatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return "N/A"
        } else {
          return cellContent
        }
      },
    },
    {
      dataField: "email",
      text: "Email",
      searchable: true,
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return <>N/A</>
        } else {
          return <>{cellContent}</>
        }
      },
      csvFormatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return "N/A"
        } else {
          return cellContent
        }
      },
    },
    {
      dataField: "mobile",
      text: "Mobile",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return <>N/A</>
        } else {
          return <>{cellContent.replace("+", "")}</>
        }
      },
      csvFormatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return "N/A"
        } else {
          return cellContent.replace("+", "")
        }
      },
    },
    {
      dataField: "registrationDate",
      text: "Registration Date",
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{dateFormat(cellContent)}</span>
      },
      // csvFormatter: (cellContent, row) => {
      //   return dateFormat(cellContent)
      // },
    },

    {
      dataField: "department",
      text: "Department",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return <>N/A</>
        } else {
          return <>{cellContent}</>
        }
      },
      csvFormatter: (cellContent, row) => {
        if (cellContent === null || cellContent === "") {
          return "N/A"
        } else {
          return cellContent
        }
      },
    },
    {
      dataField: "userId",
      text: "User Id",
      sort: true,
      searchable: true,
    },
    {
      dataField: "isActive",
      text: "Active",
      sort: true,
      searchable: true,
      csvExport: false,
      formatter: (cellContent, row) => {
        if (cellContent === true) {
          return (
            <Badge
              className={"font-size-12 badge-soft-success"}
              color="success"
              pill
            >
              {cellContent.toString()}
            </Badge>
          )
        }
        if (cellContent === false) {
          return (
            <Badge
              className={"font-size-12 badge-soft-danger"}
              color="danger"
              pill
            >
              {cellContent.toString()}
            </Badge>
          )
        }
      },
    },
    {
      dataField: "_id",
      text: "Action",
      hidden: isUser(),
      csvExport: false,
      formatter: (cellContent, row) => {
        if (row.isActive === true) {
          return (
            <UncontrolledDropdown className="my-3">
              <DropdownToggle href="#" className="card-drop" tag="i">
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu right>
                {row.firstName !== "SELLCO USER" ? (
                  <DropdownItem onClick={() => handleActionTableUser("update")}>
                    <i
                      className="mdi mdi-pencil font-size-18 mr-1 color-primary"
                      id="edittooltip"
                    />
                    Update User
                  </DropdownItem>
                ) : null}

                <DropdownItem href={"/profile/" + row.userId}>
                  <i className="mdi mdi-eye font-size-18 text-success mr-1" />
                  View User
                </DropdownItem>

                <DropdownItem
                  onClick={() => handleActionTableUser("deactivate")}
                >
                  <i
                    className="mdi mdi-close font-size-18 mr-1 color-danger"
                    id="deletetooltip"
                  />
                  Deactivate User
                </DropdownItem>
                {/* {user.role === "SuperAdmin" && row.isActive === false ? (
                  <DropdownItem onClick={() => handleArchiveUser(row.userId)}>
                    <i className="fas fa-sync font-size-18 text-success mr-1" />
                    Archive User
                  </DropdownItem>
                ) : null} */}
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        if (row.isActive === false) {
          return (
            <UncontrolledDropdown className="my-3">
              <DropdownToggle href="#" className="card-drop" tag="i">
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu right>
                {row.firstName !== "SELLCO USER" ? (
                  <DropdownItem onClick={() => handleActionTableUser("update")}>
                    <i
                      className="mdi mdi-pencil font-size-18 mr-1 color-primary"
                      id="edittooltip"
                    />
                    Update User
                  </DropdownItem>
                ) : null}

                <DropdownItem href={"/profile/" + row.userId}>
                  <i className="mdi mdi-eye font-size-18 text-success mr-1" />
                  View User
                </DropdownItem>
                <DropdownItem onClick={() => handleActionTableUser("activate")}>
                  <i
                    className="fas fa-plus font-size-18 mr-1 color-active"
                    id="deletetooltip"
                  />
                  Activate User
                </DropdownItem>
                {user?.role === "SuperAdmin" && row.isActive === false ? (
                  <DropdownItem onClick={() => handleArchiveUser(row.userId)}>
                    <i className="fas fa-sync font-size-18 text-success mr-1" />
                    Archive User
                  </DropdownItem>
                ) : null}
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
      },
    },
  ]
}

export default UserColumns

import React from "react"
import { Button } from "reactstrap"
import { statusPillsBool } from "../../helpers/commonhelpers/statusPills"
import dateFormat from "../../helpers/commonhelpers/dateFormat"

const MerchantColumns = handleOpenOverview => {
  // const getActivePg = (cellContent) => {

  //   const x = cellContent.filter(function (item) {
  //     if (item.isActive === true)
  //       return item.paymentGateway
  //   })

  //   console.log(x)
  // }

  return [
    {
      dataField: "merchantId",
      text: "Id",
      sort: true,
    },
    {
      dataField: "merchantName",
      text: "Merchant Name",
      sort: true,
      searchable: true,
    },
    {
      dataField: "type",
      text: "Business Type",
      sort: true,
      searchable: true,
    },
    {
      dataField: "registrationDate",
      text: "Registration Date",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        return <span>{dateFormat(cellContent)}</span>
      },
    },
    {
      dataField: "paymentGatewayEnrollments",
      text: "Active PG",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        try {
          var x = cellContent.find(x => x.isActive === true)
          return <b>{x.paymentGateway}</b>
        } catch {
          return (
            <span>
              {" "}
              Something went wrong. Reasons : No Active PG or Multiple PGs are
              active.
            </span>
          )
        }
      },
    },
    {
      dataField: "paymentGatewayEnrollments.paymentGateway",
      text: "PG",
      sort: true,
      searchable: true,
      hidden: true,
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (cellContent === null) {
          return <span>{statusPillsBool(cellContent, "pending", true)}</span>
        } else {
          return (
            <span>
              {statusPillsBool(cellContent, cellContent.toString(), true)}
            </span>
          )
        }
      },
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        // return <a href={cellContent} target="_blank">
        //   {cellContent}
        // </a>

        return (
          <>
            <Button
              color="primary"
              className="btn-sm btn-rounded"
              // style={{borderColor:"#ffffff00"}}
              onClick={() => handleOpenOverview(cellContent)}
            >
              {" "}
              View Details{" "}
            </Button>
          </>
        )

        // <Button className="btn" style={{backgroundColor:"#ffffff00", color:"black", borderColor:"#ffffff00"}}
        // onClick={() => handleOpenOverview({rowIndex})}>{cellContent}</Button>
      },
    },
  ]
}

export default MerchantColumns
